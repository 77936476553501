import Swiper from 'swiper';

export default class {
  constructor() {
    this.swiper = null;
    this.options = {
      slider: '.js-hero-slider',
    }

    window.addEventListener('load', () => {
      this.init();
    });
  }

  init() {
    const sliders = Array.from(document.querySelectorAll(this.options.slider));
    const interleaveOffset = 0.5;

    if (!sliders.length) {
      return;
    }

    sliders.forEach(slider => {
      const slides = slider.querySelectorAll('.swiper-slide');

      this.swiper = new Swiper(slider, {
        loop: slides.length > 1,
        speed: 1000,
        autoplay: true,
        spaceBetween: 0,
        delay: 3000,
        grabCursor: true,
        watchSlidesProgress: true,
        mousewheelControl: true,
        keyboardControl: true,
        on: {
          progress: function() {
            const swiper = this;
            for (let i = 0; i < swiper.slides.length; i++) {
              let slideProgress = swiper.slides[i].progress;
              let innerOffset = swiper.width * interleaveOffset;
              let innerTranslate = slideProgress * innerOffset;
              swiper.slides[i].querySelector('.slide-inner').style.transform =
                `translate3d(${innerTranslate}px, 0, 0)`;
            }
          },
          touchStart: function() {
            const swiper = this;
            for (let i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = '';
            }
          },
          setTransition: function(speed) {
            const swiper = this;
            for (let i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = speed + 'ms';
              swiper.slides[i].querySelector('.slide-inner').style.transition =
                speed + 'ms';
            }
          },
        },
      });
    })
  }
}
