const forEach = (nodes, callback) => {
  if(nodes) {
    // Because IE...
    for(let i = 0; i < nodes.length; i++) {
      callback(nodes[i], i);
    }
  }
};

export { forEach };
