/* global wc_load_more */
require('intersection-observer');
import axios from 'axios';
import qs from 'qs';
import Sidebar from './sidebar';

class AjaxLoad {
  constructor() {
    this.classes = {
      'loadMoreContainer': '.js-section--load-more',
      'placeholder': '.js-placeholder--load-more',
      'active': 'is-active',
    };
    this.initLoadMore();
  }

  initLoadMore() {
    const loadMoreContainer = document.querySelector(this.classes.loadMoreContainer);

    if (!loadMoreContainer) {
      return false;
    }

    const placeholder = document.querySelector(this.classes.placeholder);

    const intersectionObserver = new IntersectionObserver(entries => {
      const entry = entries && entries.length && entries[0];
      if (entry && (entry.isIntersecting || entry.intersectionRatio > 0)){
        if (placeholder) {
          // Disable the load more button
          placeholder.classList.add(this.classes.active);

          const placeholderDataset = placeholder.dataset;
          const options = {
            'nonce': wc_load_more.nonce,
            'category_id': wc_load_more.category_id,
            'is_archive': wc_load_more.is_archive,
            'is_search': wc_load_more.is_search,
            'action': 'get_posts',
            'load_count': placeholderDataset.loadCount || wc_load_more.load_count,
            'visible_ids': placeholderDataset.visibleIds || [],
            'search_query': placeholderDataset.search || null,
            'visible_terms': [],
            'post_type': placeholderDataset.postType || wc_load_more.post_type,
            'taxonomies': [] || wc_load_more.taxonomies,
          };

          axios.post(wc_load_more.url, qs.stringify(options))
            .then(response => {
              const posts = response.data.data.posts;
              const postIds = response.data.data.post_ids;
              const hasMorePosts = response.data.data.has_more_posts;

              if (posts) {
                loadMoreContainer.insertAdjacentHTML('beforeend', posts);
              }

              if (hasMorePosts) {
                // More posts can be fetched, enable the load more button
                placeholder.classList.remove(this.classes.active);

                if (postIds) {
                  placeholderDataset.visibleIds = JSON.stringify(JSON.parse(placeholderDataset.visibleIds).concat(postIds));
                }
              }

              if (!posts || !hasMorePosts) {
                // Remove the load more button
                placeholder.remove();
              }

              this.dispatchEvent();
            }).catch(error => {
            console.log(error);
            // Remove the load more button
            placeholder.remove();
          }).finally(() => {
            new Sidebar();
          });
        }
      }
    });

    const config = { subtree: false, childList: true };
    const observer = new MutationObserver(() => {})

    if (placeholder) {
      intersectionObserver.POLL_INTERVAL = 100;
      intersectionObserver.observe(placeholder);
      // Create an observer instance linked to the callback function
      observer.observe(loadMoreContainer, config);
    }
  }

  dispatchEvent() {
    const event = new CustomEvent('ajaxLoaded');
    document.dispatchEvent(event);
  }
}

export default AjaxLoad;
