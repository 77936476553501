
import lozad from 'lozad';

export default class Lozad {
  constructor() {
    this.init();
  }

  init() {
    const windowHeight = window.innerHeight;

    const observer = lozad('.lozad', {
      threshold: 0,
      rootMargin: windowHeight + 'px',
    });
    observer.observe();
  }
}
