export default class {
  constructor() {
    this.options = {
      sidebar: '.js-sidebar',
      section: '.js-content-section',
    };

    this.init();
  }

  init() {
    const sidebar = document.querySelector(this.options.sidebar);
    let firstSectionSpacing = null;
    let sectionsHeight = null;
    let sidebarHeight = null;

    if (!sidebar) return;

    sidebarHeight = sidebar.offsetHeight;

    const sections = document.querySelectorAll(this.options.section);
    if (sections.length > 0) {
      const wrapper = sections[0].querySelector('.section__wrapper');
      firstSectionSpacing = parseInt(window.getComputedStyle(wrapper, null).getPropertyValue('padding-top'));
    }

    sections.forEach(section => {
      sectionsHeight += section.offsetHeight;
    })

    if (sidebarHeight > sectionsHeight) {
      sidebar.style.height = `${sidebarHeight - sectionsHeight}px`;
    } else {
      sidebar.style.height = 0;
    }

    sidebar.style.top = `-${sectionsHeight - firstSectionSpacing}px`;
  }
}
