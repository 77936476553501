if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

Element.prototype.remove = function() {
  this.parentElement.removeChild(this);
}

NodeList.prototype.remove = HTMLCollection.prototype.remove = function() {
  for(let i = this.length - 1; i >= 0; i--) {
    if(this[i] && this[i].parentElement) {
      this[i].parentElement.removeChild(this[i]);
    }
  }
}
if (typeof Object.assign != 'function') {
  Object.assign = function(target) {
    'use strict';
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }

    target = Object(target);
    for (let index = 1; index < arguments.length; index++) {
      const source = arguments[index];
      if (source != null) {
        for (let key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  };
}
import 'es6-object-assign';
import 'core-js/stable/promise/index'
import 'core-js/features/iterator/for-each';
import 'core-js/features/array/from';
import 'custom-event-polyfill';
import Menu from './components/menu';
import Submenu from './components/mobile-submenu.js';
import ScrollActions from './components/scroll-actions';
import HeroSlider from './components/hero-slider';
import Sidebar from './components/sidebar';
import Lozad from './components/lozad';
import Gallery from './components/gallery';
import AjaxLoad from './components/AjaxLoad';

window.addEventListener('DOMContentLoaded', () => {
  new Lozad();
  new Sidebar();
  new Menu();
  new Submenu();
  new ScrollActions();
  new AjaxLoad();
  new HeroSlider();
  new Gallery();
});
