import { debounce } from '../util/debounce';

export default class {
  constructor() {
    this.menuToggleElem = Array.from(document.querySelectorAll('.navbar .menu-item-has-children'));

    if (!this.menuToggleElem) {
      return false;
    }

    window.addEventListener('load', () => {
      this.init();
    })
  }

  init() {
    this.menuToggleElem.forEach(function (element) {
      const submenuItem = element.querySelector('.sub-menu');

      if (!submenuItem) {
        return false;
      }

      const submenuHeight = submenuItem.offsetHeight;
      submenuItem.style.height = '0px';

      element.addEventListener('click', debounce((e) => {
        element.classList.toggle('sub-menu-open');
        e.preventDefault();

        submenuItem.style.height = submenuHeight + 'px';

        if (!element.classList.contains('sub-menu-open')) {
          submenuItem.style.height = '0px';
        }
      }, 100));
    })
  }
}
