export default class {
  constructor() {
    this.class = {
      menuToggleSelector: 'js-menu-toggle',
      bodyMenuOpen: 'menu-is-open',
      navigation: 'js-navigation',
      menuItem: 'menu-item',
      menuToggleOpen: 'is-active',
      menuIsClosing: 'menu-is-closing',
    };

    this.menuToggleElem = document.querySelector(`.${this.class.menuToggleSelector}`);
    this.body = document.body;
    if (!this.menuToggleElem) {
      return false;
    }

    window.addEventListener('load', () => {
      this.init();
    });
  }

  init() {
    this.menuToggleElem.addEventListener('click', () => {
      this.menuToggleElem.classList.toggle(this.class.menuToggleOpen);
      this.body.classList.toggle(this.class.bodyMenuOpen);

      if (!this.body.classList.contains(this.class.bodyMenuOpen)) {
        this.body.classList.add(this.class.menuIsClosing);

        setTimeout(() => {
          this.body.classList.remove(this.class.menuIsClosing);
        }, 300);
      }
    });
  }
}
