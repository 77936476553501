import { forEach } from '../util/helpers';
import 'lightgallery.js';

export default class {
  constructor() {
    this.options = {
      gallery: '.js-gallery',
    };

    this.init();
  }

  init() {
    const items = document.querySelectorAll(this.options.gallery);

    /* eslint-disable */
    if (items && items.length > 0) {
      forEach(items, item => {
        lightGallery(item, {
          download: false,
        })
      })
    }
    /* eslint-enable */
  }
}
