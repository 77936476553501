export default class ScrollActions {
  constructor() {
    this.lastScrollTop = 0;
    this.scrollUpDetection = true;
    this.scrollOffset = 1;
    this.scrollUpOffset = window.innerHeight / 4;
    this.mainMenuSelector = '.header';
    this.mainMenuScrollClass = 'header--scroll';
    this.mainMenuScrollUpClass = 'header--scroll-up';
    this.mainMenuElem = document.querySelector(this.mainMenuSelector);

    if (!this.mainMenuElem) {
      return false;
    }

    this.mainMenuOnScroll();
    this.onScroll();
  }

  onScroll() {
    this.scrollTop = (document.body.scrollTop || document.documentElement.scrollTop) || 0;
    this.lastScrollTop = this.scrollTop;

    window.addEventListener('scroll', () => {
      this.mainMenuOnScroll();
    });
  }

  mainMenuOnScroll() {
    this.scrollTop = (document.body.scrollTop || document.documentElement.scrollTop) || 0;

    // Change the sticky header styling on scroll
    if (this.scrollTop > this.scrollOffset) {
      this.mainMenuElem.classList.add(this.mainMenuScrollClass);
    } else {
      this.lastScrollTop = -1;
      this.mainMenuElem.classList.remove(this.mainMenuScrollClass);
    }

    if (!this.scrollUpDetection) {
      return false;
    }

    // Check whether the class should be added on scroll up
    this.mainMenuElem.classList.remove(this.mainMenuScrollUpClass);

    let goingUp = (this.scrollTop < this.lastScrollTop) && (this.scrollTop > this.scrollUpOffset);

    if (goingUp) {
      this.mainMenuElem.classList.add(this.mainMenuScrollUpClass);
    }

    this.lastScrollTop = this.scrollTop;
  }

  resetScrollClasses() {
    this.mainMenuElem.classList.remove(this.mainMenuScrollUpClass);
  }
}
